body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1c1c1e; /*dark*/
  color: #f2f2f7; /*dark*/
}

.container {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #29292c; /*dark*/
  transition: background-color 1s ease; /* Add transition for smooth background color change */
}

.login-container {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  background-color: #29292c; /*dark*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  display: block;
  color: #f2f2f7; /*dark*/
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="password"],
.form-group textarea {
  width: 100%;
  margin-left: 5px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #47474d; /*dark*/
  background-color: #3a3a3c; /*dark*/
  color: #f2f2f7; /*dark*/
}

.form-group textarea {
  resize: none;
}

.form-group .input-area {
  display: flex;
  align-items: center;
}

.form-group .input-area label {
  width: auto;
}

.submit-button,
button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007aff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.qr-button {
  margin-bottom: 1rem;
}

.email-input {
  padding-top: 5px;
}

/* App.css */

/* Styling for the form group to make label and dropdown appear side by side */
.form-group-2 {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

#sheetSelect {
  margin-left: 1rem;
  padding: 0.5rem;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
}

/* Add dark mode styles for the input fields */
.input-area input {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
}

.input-area label {
  color: #fff;
}

.open-button {
  margin-left: 1rem;
}
@media (max-width: 900px) {
  .container {
    width: 88%;
  }
  .open-button {
    margin-left: 0rem;
  }
  h1 {
    font-size: 180%;
  }

  .form-group-2 {
    flex-direction: column;
    align-items: flex-start;
  }

  #sheetSelect {
    margin-left: 0;
    margin-top: 0.5rem;
    width: 100%;
  }

  .input-area label {
    margin-bottom: 0.5rem;
  }

  .email-input {
    margin-top: 1rem;
  }
  .login-container {
    width: 90%;
  }
}
